var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "percentage" },
    [
      _c(
        "CBNumber",
        _vm._b(
          {
            attrs: { suffix: "%" },
            model: {
              value: _vm.showVal,
              callback: function ($$v) {
                _vm.showVal = $$v
              },
              expression: "showVal",
            },
          },
          "CBNumber",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }